var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "info" }, [
      _c("div", [_vm._v("导向轮方向：" + _vm._s(_vm.runningStateSteer) + "°")]),
      _c("div", [_vm._v("电量： " + _vm._s(_vm.runningStateSoc) + "%")]),
      _c("div", [
        _vm._v("车头朝向： " + _vm._s(_vm.locationHeading) + "° "),
        _c("img", { ref: "imgRef", attrs: { src: _vm.arrowUrl } }),
      ]),
      _vm.vehicleItem.warnStatus === 3 && _vm.vehicleItem.managedRequest.time
        ? _c("div", [
            _vm._v(
              "请求托管时间： " + _vm._s(_vm.vehicleItem.managedRequest.time)
            ),
          ])
        : _vm._e(),
      _vm.vehicleItem.warnStatus === 3 &&
      _vm.vehicleItem.managedRequest.managedRequestStatus === 888
        ? _c("div", [_vm._v("托管状态：正在发送退出托管")])
        : _vm._e(),
      _c("div", [_vm._v("刹车状态：无")]),
      _c("div", [
        _vm._v(
          "速度：" +
            _vm._s(
              _vm.vehicleItem.runningState != null
                ? Number(_vm.vehicleItem.runningState.speed).toFixed(2)
                : 0
            ) +
            "KM/H"
        ),
      ]),
      _c("div", [
        _vm._v(
          "最近一次上报：" + _vm._s(_vm.vehicleItem ? _vm.vehicleItem.time : "")
        ),
      ]),
    ]),
    _c("img", { attrs: { src: _vm.radarUrl, alt: "" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }