var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vehicle-monitor-item" }, [
    _c(
      "div",
      { staticClass: "vehicle-monitor-vedio" },
      [
        _c("vehicle-monitor-vedio", {
          attrs: { videoType: _vm.videoType, vehicleItem: _vm.vehicleItem },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "vehicle-monitor-func" },
      [
        _c("vehicle-monitor-header", {
          attrs: {
            vehicleItem: _vm.vehicleItem,
            taskStatusOptions: _vm.taskStatusOptions,
          },
          on: { onVehicleMonitorEvent: _vm.handleVehicleMonitorEvent },
        }),
        _c("vehicle-monitor-content", {
          attrs: { vehicleItem: _vm.vehicleItem },
          on: { onVehicleMonitorEvent: _vm.handleVehicleMonitorEvent },
        }),
        _c("vehicle-monitor-footer", {
          attrs: { vehicleItem: _vm.vehicleItem },
          on: { onVehicleMonitorEvent: _vm.handleVehicleMonitorEvent },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }