<template>
  <div class="vehicle-monitor-item">
    <div class="vehicle-monitor-vedio">
      <vehicle-monitor-vedio :videoType="videoType" :vehicleItem="vehicleItem" />
    </div>
    <div class="vehicle-monitor-func">
      <vehicle-monitor-header :vehicleItem="vehicleItem" :taskStatusOptions="taskStatusOptions" @onVehicleMonitorEvent="handleVehicleMonitorEvent" />
      <vehicle-monitor-content :vehicleItem="vehicleItem" @onVehicleMonitorEvent="handleVehicleMonitorEvent" />
      <vehicle-monitor-footer :vehicleItem="vehicleItem" @onVehicleMonitorEvent="handleVehicleMonitorEvent" />
    </div>
  </div>
</template>
<script>
import VehicleMonitorVedio from '../VehicleMonitorVedio'
import VehicleMonitorHeader from '../VehicleMonitorHeader'
import VehicleMonitorContent from '../VehicleMonitorContent'
import VehicleMonitorFooter from '../VehicleMonitorFooter'
export default {
  name: 'VehicleMonitorItem',
  props: {
    vehicleItem: {
      type: Object,
      default: () => {}
    },
    taskStatusOptions: {
      type: Array,
      default: () => {}
    },
    videoType: {
      type: String,
      default: 'rtmp'
    }
  },
  components: { VehicleMonitorVedio, VehicleMonitorHeader, VehicleMonitorContent, VehicleMonitorFooter },
  methods: {
    handleVehicleMonitorEvent(eventName, eventArgs) {
      this.$emit('onVehicleMonitorEvent', eventName, eventArgs)
    }
  }
}
</script>
<style lang="less" scoped>
.vehicle-monitor-item {
  display: flex;
  border-bottom: 1px solid @borderColor;
  .vehicle-monitor-vedio {
    min-width: 350px;
    max-width: 500px;
    flex: 1;
  }
  .vehicle-monitor-func {
    position: relative;
    min-width: 350px;
    flex: 1;
    display: flex;
    flex-direction: column;
    .vehicle-monitor-content {
      flex: 1;
    }
  }
}
</style>
<style lang="less">
.vehicle-monitor-vedio {
  .video-wrapper .player {
    min-height: 200px;
  }
}
</style>
