var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["header header" + _vm.getStatusCss()] }, [
    _c("span", [
      _vm._v(_vm._s(_vm.vehicleItem.vehicleId) + "-" + _vm._s(_vm.workStatus)),
    ]),
    _c(
      "span",
      [
        _c(
          "a-button",
          {
            attrs: { type: "primary", size: "small" },
            on: { click: _vm.handleSelect },
          },
          [_vm._v("选中")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }