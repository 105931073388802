<template>
  <div :class="['header header' + getStatusCss()]">
    <span>{{ vehicleItem.vehicleId }}-{{ workStatus }}</span>
    <span><a-button type="primary" size="small" @click="handleSelect">选中</a-button></span>
  </div>
</template>
<script>
export default {
  props: {
    status: {
      type: Number,
      default: () => {}
    },
    vehicleItem: {
      type: Object,
      default: () => {}
    },
    taskStatusOptions: {
      type: Array,
      default: () => {}
    }
  },
  watch: {
    taskStatusOptions(newVal, oldVal) {
      // console.log('taskStatusOptions')
    }
  },
  computed: {
    workStatus() {
      // console.log('getWorkStatus')
      if (!this.vehicleItem.online) {
        return '离线'
      } else if (this.vehicleItem.warnStatus === 2) {
        return '智驾任务挂起-硬件正常'
      } else if (this.vehicleItem.warnStatus === 3) {
        return '请求托管-硬件正常'
      }
      if (!this.taskStatusOptions) {
        return '-硬件正常'
      }
      const workStatus = this.vehicleItem.businessState ? this.vehicleItem.businessState.state : -1
      return this.selectDictLabel(this.taskStatusOptions, workStatus) + '-硬件正常'
    }
  },
  methods: {
    handleSelect() {
      this.$emit('onVehicleMonitorEvent', 'setCurrentVehicleId', this.vehicleItem.vehicleId)
    },
    getStatusCss() {
      if (!this.vehicleItem.online) {
        return '--off'
      } else if (this.vehicleItem.warnStatus === 2) {
        return '--error'
      } else if (this.vehicleItem.warnStatus === 3) {
        return '--error'
      } else {
        return '--ok'
      }
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.header--ok {
  background-color: @success-color;
  color: #fff;
}
.header--error {
  background-color: @danger;
  color: #fff;
}
.header--off {
  background-color: @grey;
  color: #fff;
}
</style>
