<template>
  <div class="content">
    <div class="info">
      <div>导向轮方向：{{ runningStateSteer }}°</div>
      <div>电量： {{ runningStateSoc }}%</div>
      <div>车头朝向： {{ locationHeading }}° <img ref="imgRef" :src="arrowUrl" /></div>
      <div v-if="vehicleItem.warnStatus === 3 && vehicleItem.managedRequest.time">请求托管时间： {{ vehicleItem.managedRequest.time }}</div>
      <div v-if="vehicleItem.warnStatus === 3 && vehicleItem.managedRequest.managedRequestStatus === 888">托管状态：正在发送退出托管</div>
      <div>刹车状态：无</div>
      <div>速度：{{ vehicleItem.runningState != null ? Number(vehicleItem.runningState.speed).toFixed(2) : 0 }}KM/H</div>
      <div>最近一次上报：{{ vehicleItem ? vehicleItem.time : '' }}</div>
    </div>
    <img :src="radarUrl" alt="" />
  </div>
</template>
<script>
import get from 'lodash/get'
import MonitorBase from '@/views/monitor/monitorBase'
export default {
  mixins: [MonitorBase],
  props: {
    vehicleItem: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      radarUrl: require('@/assets/images/radar.png')
    }
  },
  watch: {
    vehicleItem(newVal, oldVal) {
      // console.log('vehicleItem', newVal)
    }
  },
  computed: {
    locationHeading() {
      const headingVal = get(this.vehicleItem, 'location.heading', 0)
      this.$nextTick(() => {
        this.setHeadingArrow(this.vehicleItem, this.$refs.imgRef)
      })
      return headingVal ? headingVal.toFixed(2) : headingVal
    },
    runningStateSoc() {
      const soc = get(this.vehicleItem, 'runningState.soc', 0)
      return soc ? soc.toFixed(0) : soc
    },
    runningStateSteer() {
      const steer = Number(get(this.vehicleItem, 'runningState.steer', 0))
      const res = steer < 0 ? '左' + Math.abs(steer).toFixed(2) + '° ' : '右' + Math.abs(steer).toFixed(2) + '° '
      return res
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
  padding: 5px;
  .info {
    margin-bottom: 10px;
    color: @primary-font-color;
    img {
      width: 20px;
      height: 20px;
      min-width: 20px;
    }
  }
  > img {
    margin-top: 10px;
    width: 80px;
  }
}
</style>
